.pg-public{
  display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 36rem;
    margin: 1rem;
    padding: 1.5rem;
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12);
    border-radius: 4px
  }

  .pg-cntr-no-header{
    display: flex;
      flex-direction: column;
      align-items: center;
      padding: 1rem 1rem 3rem;
      margin-bottom: 1.5rem;
      height: calc(100vh - 42px);
      overflow: auto;
      position: fixed;
      top: 41px;
      width: 100%;
  }
.no-img-cntr{display: flex; justify-content: center; align-items: center; border: 1px solid #bcbcbc}
.action-card-bg{
  height: 100vh;
    width: 100vw;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
}
.action-card-image svg{height: 120px; width: 120px;}
.dashboard-title{font-size: 1rem; font-weight: bold; margin-right: 0.6rem; margin-top: 0.5rem}
.dashboard-location{font-size: 0.9rem; line-height: 1.5rem;}
.action-card-bg{
  height: 100vh;
    width: 100vw;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
}

.action-card-details{display: flex; margin: 1rem; align-items: flex-start;}
.action-card-actions{flex-grow: 1; display: flex; flex-direction: column;}
.action-card-text{padding-left:1rem; width: calc(100% - 120px);}
.background-root{
  z-index: -1; position: fixed;
  right: 0px;
  bottom: 0px;
  top: 0px;
  left: 0px;
  background-color: rgba(0, 0, 0, 0.95);
  -webkit-tap-highlight-color: transparent;
  touch-action: none;
}

.modal-container{
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
}
#onIt{flex-grow: 1;}
.cancel-icon{position: absolute; right: 0rem; top: 0rem; font-size: 2rem; background-color: #fff}
/* #onIt, .slide{background-color: rgb(221, 221, 221) !important;} */
.action-slide{padding: 0.05rem;}
.action-title { font-weight: 700; margin-bottom: 1rem; }
.action-description{ font-size: 0.925rem; text-align: left; }
.action-btn-title{ font-size: 0.75rem; text-align: center; }
.slide-card{height: 100%}
.slide-content{padding: 1rem 1rem 2rem;}
.carousel .carousel-status{text-shadow: none !important; color: #000 !important;}
.carousel .slide{background-color: transparent !important;}
.btn-cntr{ margin: 1rem; display: flex; justify-content: center;}
.comment-blk{
  border: 1px solid rgb(188, 188, 188);
    border-radius: 4px;
    padding: 1rem;
    font-size: 0.95rem;
    color: #999;
}
.userComment{font-size: 0.8rem;}
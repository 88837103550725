.drawer-paper{width: 272px; justify-content: space-between;}
.drawer-top{display: flex; flex-direction: column;}
.drawer-option-cntr{ 
  display: flex;
  justify-content: space-between;
  margin: 0rem 1rem;
  padding: 0.85rem 0rem;
  align-items: center;
  border-bottom: 1px solid #ececec;
  font-size: 0.95rem;}
  .close-btn{align-self: flex-end; margin: 1rem;}
  .copyright{font-size: 0.65rem; margin: 0rem 1rem; padding: 1rem 0rem;}
  .drawer-bottom{justify-content: center; display: flex; flex-direction: column;}
  .profile-blk{display: flex; flex-direction: column; justify-content: center; align-items: center; margin-bottom: 1.5rem;}